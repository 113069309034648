import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../styles/styles.scss';
import 'normalize.css';
import Navbar from './navigation/navbar';
import FooterNav from './navigation/Footer';
import AskForVat from './settings/vat';
import UpArrow from '../images/icons/chevron-up.png';
import { Script } from 'gatsby';

const Layout = ({ children, language, localizations }) => {
  const [showButton, setShowButton] = useState(false);
  if (language === undefined) {
    language = 'nl-NL';
  }
  const [locale, setLocale] = useState(language);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // for smoothly scrolling
    });
  };

  return (
    <React.Fragment>
      <Navbar
        locale={locale}
        setLocale={setLocale}
        localizations={localizations}
      ></Navbar>
      <main locale={locale}>{children}</main>
      <AskForVat locale={locale} />
      <FooterNav locale={locale}></FooterNav>
      {/* 
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <img src={UpArrow} alt="^" width={18} height={18} />
        </button>
      )}
         */}
      <Script id="livechat-script" strategy="post-hydrate">
        {`
          window.__lc = window.__lc || {};
          window.__lc.license = 18993615;
          window.__lc.integration_name = "manual_onboarding";
          window.__lc.product_name = "livechat";
          (function(n,t,c){
            function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}
            var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},
            once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},
            get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");
            return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){
            var n=t.createElement("script");
            n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",
            t.head.appendChild(n)}};
            !n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e
          }(window,document,[].slice));
        `}
      </Script>

      {/* Add noscript fallback */}
      <noscript>
        <a href="https://www.livechat.com/chat-with/18993615/" rel="nofollow">
          Chat with us
        </a>
        , powered by{' '}
        <a
          href="https://www.livechat.com/?welcome"
          rel="noopener nofollow"
          target="_blank"
        >
          RentVisie
        </a>
      </noscript>
    </React.Fragment>
  );
};

export default Layout;
